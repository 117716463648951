<template>
  <div class="del-wrap">
    <el-dialog
      title="审核"
      :visible="checkDialogVisible"
      :close-on-press-escape="false"
      :show-close="false"
      width="500px"
      center
      top="0"
      @close="closeModal"
    >
      <div class="check-wrap">
        <el-row style="line-height:40px">
          <el-col :span="7">
            <span>分成比：</span>
          </el-col>
          <el-col :span="17">
            <el-select
              style="width:100%;margin-left:10px"
              v-model="separate_ratio"
              placeholder="请选择"
            >
              <el-option
                :label="item.title"
                v-for="item in acceptRatioList"
                :key="item.key"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row style="margin-top:20px;line-height:40px">
          <el-col :span="7">
            <span>拉新主播返点：</span>
          </el-col>
          <el-col :span="17">
            <el-select
              style="width:100%;margin-left:10px"
              v-model="increase_reward_ratio"
              placeholder="请选择"
            >
              <el-option
                :label="item.title"
                v-for="item in ratioList"
                :key="item.key"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
        <p>确认介绍该经纪人成为中能魔力线上经纪人（您的介绍费将以分成形式体现，详见《平台使用手册》）</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="refuse" @click="submitCheck(2)">拒绝</el-button>
        <el-button class="wait-check" @click="submitCheck(0)">待审核</el-button>
        <el-button type="primary" @click="submitCheck(1)" style="box-shadow:none;">通过</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    checkDialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      separate_ratio: 50,
      increase_reward_ratio: 0,
      ratioList: [],
      acceptRatioList: [], //分成比列表
    };
  },
  created() {
    this._initState();
  },
  methods: {
    async _initState() {
      const { status_code, message } = await this.$api.increaseListApi();
      if (status_code === 200) {
        this.ratioList = message;
      }
      // 请求分成比
      const {
        status_code: acceptRatioList_status_code,
        message: acceptRatioList,
      } = await this.$api.acceptRatioListApi();
      if (acceptRatioList_status_code == 200) {
        this.acceptRatioList = acceptRatioList;
      }
    },
    submitCheck(status) {
      this.$emit("submitCheck", {
        status,
        separate_ratio: this.separate_ratio,
        increase_reward_ratio: this.increase_reward_ratio,
      });
    },
    closeModal(){
      this.$emit('closeModal')
    }
  },
};
</script>

<style lang="less" scoped>
.content {
  text-align: center;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
.del-wrap /deep/ button.el-button.el-button--default {
  width: 85px;
  height: 32px;
}
.check-wrap {
  width: 357px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  p {
    margin-top: 20px;
    padding: 10px;
    background: rgba(80, 157, 237, 0.1);
    font-size: 14px;
    color: #509ded;
    line-height: 20px;
  }
}
.wait-check {
  background: #509ded;
  color: #fff;
}
.refuse {
  color: #fff;
  background: #fab000;
}
.del-wrap /deep/.el-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
}
</style>